.event-sales-content {
    display: grid;
    grid-template-columns: 53vw 23vw;
    gap: 3vw;

    .content {
        margin-top: 2vw;
        padding-left: 0vw;

        &.c1 {
            border-radius: 1vw;
            padding-left: 0vw;
            padding: 0.5vw;

            .title {
                font-size: 1.5vw;
                font-family: "Expletus Sans";
                margin-top: 0.5vw;
                padding: 0.5vw;
            }

            .table-container {
                max-height: 30vw;
                overflow-y: auto;
                overflow-x: hidden;

                .main-table {
                    position: sticky;
                    top: 0;
                    background: white;
                }
            }

            .table {
                border-collapse: collapse;
                margin-right: auto;
                margin-left: auto;
                padding: 0.5vw;
                display: flex;
                flex-direction: column;

                .empty {
                    position: fixed;
                    top: 21vw;
                    left: 26.5vw;
                    font-size: 4vw;
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
        
                .row {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 1vw;
                    margin-top: 0.5vw;

                    &.salesCard {
                        cursor: pointer;
                    }
        
                    .col {
                        display: grid;
                        grid-template-columns: 17vw 14vw 19vw;
                        justify-content: space-between;
        
                        .name, .amount, .date {
                            font-size: 1.5vw;
                            display: flex;
                            margin-top: auto;
                            margin-bottom: auto;
                            color: black;
                        }
        
                        .name {
                            display: flex;
                            flex-direction: row;
                            margin-top: auto;
                            margin-bottom: auto;
                            gap: 0.5vw;
                            text-align: left;
                        }
        
                        .amount {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
        
                        .date {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            text-align: left;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
        
        
                    }
        
                    .divider {
                        height: 0.01vw;
                        width: 50vw;
                        border-radius: 2vw;
                        padding: 0.1vw;
                        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    }
                }
            }
        }

        &.c2 {
            display: flex;
            flex-direction: column;
            gap: 2vw;
        
            .label-container {
                display: flex;
                flex-direction: column;
                gap: 0.5vw;
                background: white;
                border-radius: 1vw;
                padding: 0.5vw;
    
                .label {
                    font-size: 1vw;
                    color: rgb(78, 78, 78);
                    font-style: bold;
                }
    
                &.form-2 {    
                    width: 22vw;
                }
    
                &.sales {
    
                    .container {
                        display: flex;
                        flex-direction: row;
                        gap: 1vw;
                        margin-bottom: 0.5vw;
        
                        svg {
                            width: 1.5vw;
                            height: 1.5vw;
                            fill: white;
                            stroke: white;
                            background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                            padding: 0.5vw;
                            border-radius: 1.5vw;
                        }
        
                        .text {
                            margin-top: auto;
                            margin-bottom: auto;
                            font-size: 2vw;
                        }
                    }
                }
    
            }

            .ticket-container {

                border-radius: 1vw;
                padding: 0.5vw;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-self: space-between;
                    gap: 7vw;

                    .label {
                        font-size: 1vw;
                        color: rgb(78, 78, 78);
                        font-style: bold;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .btn {
                        display: flex;
                        flex-direction: row;
                        gap: 0.4vw;
                        cursor: pointer;

                        svg {
                            width: 0.6vw;
                            height: 0.6vw;
                            fill: white;
                            stroke: white;
                            background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                            padding: 0.5vw;
                            border-radius: 1.5vw;
                        }

                        .text {
                            margin-top: auto;
                            margin-bottom: auto;
                            font-size: 1vw;
                        }
                    }
                }

                .ticketcard-container {
                    max-height: 14vw;
                    overflow-y: auto;
                    margin-top: 1vw;

                    .empty {
                        display: flex;
                        margin-right: auto;
                        margin-left: auto;
                        text-align: center;
                        justify-content: center;
                        font-size: 1vw;
                        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                    }

                    .ticketCard {
                        display: flex;
                        flex-direction: column;
                        gap: 1vw;
                        padding: 0.2vw;
                        margin-bottom: 0.5vw;
                        cursor: pointer;

                        &:hover {
                            background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                            border-radius: 1vw;
                            padding: 1vw;

                            .title {
                                color: white;
                            }

                            .wrapper {
                                .card {
                                    color: white;
                                    background: linear-gradient(to bottom right, #f34ad7, #4986cc);
                                }
                            }
                        }

                        .title {
                            font-size: 1.5vw;
                            color: black;
                            font-style: bold;
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        .wrapper {
                            display: flex;
                            flex-direction: row;
                            gap: 0.5vw;
                            margin-bottom: 0.5vw;

                            .card {
                                padding: 0.5vw;
                                border-radius: 0.5vw;
                                background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                                -webkit-background-clip: text;
                                background-clip: text;
                                color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .sales-card-popup {
        position: fixed;
        top: 8vw;
        right: 35vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1vw;
        padding-top: 2vw;
        gap: 2vw;
        align-items: center;
        background: white;
        justify-content: center;
        border-radius: 1vw;
        width: 25vw;
        height: 30vw;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 12px; /* Width of the scrollbar */
            height: 12px; /* Height for horizontal scrollbar */
        }

        /* The scrollbar track (background) */
        &::-webkit-scrollbar-track {
            background: linear-gradient(to bottom right, #F34A4A, #AA49CC); /* Color of the track */
        }

        /* The draggable part of the scrollbar */
        &::-webkit-scrollbar-thumb {
            background: white; /* Color of the scrollbar thumb */
            border-radius: 10px; /* Rounded corners */
        }

        /* Hover effect for the scrollbar thumb */
        &::-webkit-scrollbar-thumb:hover {
            background: white; /* Darker color when hovering */
        }

        .divider {
            height: 0.01vw;
            width: 24vw;
            border-radius: 2vw;
            padding: 0.08vw;
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-self: space-between;
            gap: 7vw;
            width: 25vw;
            border-radius: 1vw;
            padding: 0.5vw;

            .title {
                font-size: 1.5vw;
                background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC) !important;
                -webkit-background-clip: text !important;
                background-clip: text !important;
                color: transparent !important;
            }

            .icon-btn {
                display: flex;
                flex-direction: row;
                gap: 0.5vw;
                height: fit-content;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: auto;

                svg {
                    width: 1vw;
                    height: 1vw;
                    fill: white;
                    background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    padding: 0.5vw;
                    border-radius: 1.5vw;
                }

                .text {
                    font-size: 1vw;
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC) !important;
                    -webkit-background-clip: text !important;
                    background-clip: text !important;
                    color: transparent !important;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }

        .timeline {
            .title {
                font-size: 2vw;
                text-align: left;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .container {
                display: flex;
                flex-direction: column;
                
                .timeline-card {
                    display: flex;
                    flex-direction: row;
                    gap: 0.5vw;

                    .date-card {
                        display: flex;
                        flex-direction: column-reverse;
                        position: relative;
                        top: 0vw;

                        .bubble {
                            width: 0.8vw;
                            height: 0.8vw;
                            border-radius: 1vw;
                            padding: 0.2vw;
                            margin-top: auto;
                            margin-bottom: auto;
                            position: relative;
                            top: 1vw;
                            background: linear-gradient(to right, #F06F6F, #933EB1);
                        
                            &.first {
                                top: -0.5vw;
                            }
                        }

                        .line-connector {
                            width: 0.8vw;
                            height: 3vw;
                            background: linear-gradient(to bottom, #F06F6F, #933EB1);
                            border-radius: 1vw;
                            position: relative;
                            right: -0.19vw;
                            top: -0.5vw;
                        }
                    }

                    .content {
                        width: 10vw;
                        overflow-y: hidden;
                        display: flex;
                        flex-direction: column;
                        padding: 0.5vw;
                        border-radius: 1vw;
                        background: linear-gradient(to right, #F06F6F, #933EB1);
                        opacity: .9;

                        .title {
                            font-size: 0.8vw;
                            font-family: "Inkut Antiqua";
                            color: white;
                            margin-top: auto;
                            margin-bottom: auto;
                            text-align: left;
                            padding: 0vw;
                        }
                        
                        .date {
                            color: white;
                            text-align: center;
                            font-size: 0.8vw;
                            text-align: left;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }
                }
            }
        }

        .payment-breakdown {
            
            .title {
                font-size: 2vw;
                text-align: left;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .container {
                display: flex;
                flex-direction: column;

                .payment-breakdown-card {
                    display: grid;
                    grid-template-columns: 18vw 6vw;

                    &.bold {
                        font-weight: 700 !important;

                        .title {
                            font-weight: 700 !important;
                        }
                    }

                    .title {
                        font-size: 1vw;
                        font-family: "Inkut Antiqua";
                        color: black;
                        margin-top: auto;
                        margin-bottom: auto;
                        text-align: left;
                    }

                    .amount {
                        font-size: 1vw;
                        font-family: "Inkut Antiqua";
                        color: black;
                        margin-top: auto;
                        margin-bottom: auto;
                        text-align: right;
                        padding-right: 0.5vw;
                    }
                }
            }
        }
    }

    .create-ticket-popup {
        position: fixed;
        top: 8vw;
        right: 35vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1vw;
        padding-top: 2vw;
        gap: 2vw;
        align-items: center;
        background: white;
        justify-content: center;
        border-radius: 1vw;
        width: 25vw;
        height: 30vw;

        .form-popup {
            display: flex;
            flex-direction: column;
            width: 27vw;
            gap: 2vw;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .title {
            font-size: 2vw;
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC) !important;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            color: transparent !important;
        }

        .button {
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            color: white;
            width: 12vw;
            font-size: 1.8vw;
            text-align: center;
            padding: 0.4vw;
            border-radius: 1vw;
            margin-right: auto;
            margin-left: auto;
            cursor: pointer;

            &:hover {
                background-image: linear-gradient(to bottom left, #F34A4A, #AA49CC);
            }
        }

        .label-container {
            display: flex;
            flex-direction: column;
            gap: 0.5vw;
            background: white;
            border-radius: 1vw;
            padding: 0.5vw;
            width: 22vw;
            margin-right: auto;
            margin-left: auto;

            .label {
                font-size: 1vw;
                color: rgb(78, 78, 78);
                font-style: bold;
            }

            .textarea {
                height: 5vw;
            }

            input, .textarea {
                height: 2vw;
                background: white;
                color: black;
                outline: none;
                border: none;
            }

            input, textarea {
                width: 17vw;
                height: 2vw;
                border-radius: 2vw;
                padding: 0.5vw;
                outline: none;
            }

            textarea {
                resize: none;
                max-height: 20vw;
                overflow-y: auto;
            }
        }
    }
}
