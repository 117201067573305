.event-gallery {
    width: 20vw;
    height: 20vw;
    border-radius: 1vw;
    cursor: pointer;

    &:hover {
        .trashcan {
            visibility: visible;
        }
    }

    .popup-img {
        width: 40vw;
        height: 40vw;
        position: relative;
        top: 5vw;
        left: 28vw;
        background: white;
    }

    .download {
        width: 1.5vw;
        height: 1.5vw;
        fill: transparent;
        stroke: white;
        cursor: pointer;
        padding: 0.5vw;
        justify-content: center;
        align-items: center;
        border-radius: 1vw;
        background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
        position: relative;
        top: -34vw;
        left: 64vw;
    }
    
    img {
        width: 20vw;
        height: 20vw;
        border-radius: 1vw;
    }

    .trashcan {
        width: 1.5vw;
        height: 1.5vw;
        fill: white;
        cursor: pointer;
        padding: 0.2vw;
        border-radius: 1vw;
        background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
        position: relative;
        top: -19.8vw;
        left: 17.8vw;
        visibility: hidden;
    }
}
