.RegisterPage {
    .content {
        display: flex;
        flex-direction: row;
        column-gap: 6vw;
        width: 80vw;
        margin-top: 8vh;
        margin-right: auto;
        margin-left: auto;

        .feature-updates {
            width: 50vw;
            height: 32vw;
            overflow-y: auto;
            margin-top: auto;
            margin-bottom: auto;
            
            &::-webkit-scrollbar {
                width: 12px; /* Width of the scrollbar */
                height: 12px; /* Height for horizontal scrollbar */
            }

            /* The scrollbar track (background) */
            &::-webkit-scrollbar-track {
                background: transparent; /* Color of the track */
            }

            /* The draggable part of the scrollbar */
            &::-webkit-scrollbar-thumb {
                background: transparent; /* Color of the scrollbar thumb */
                border-radius: 10px; /* Rounded corners */
            }

            /* Hover effect for the scrollbar thumb */
            &::-webkit-scrollbar-thumb:hover {
                background: #555; /* Darker color when hovering */
            }

            .header {
                font-size: 2vw;
                text-align: center;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .sub-header {
                color: #414141;
                text-align: center;
                font-size: 1vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 1vw;
                margin-bottom: 1vw;
            }

            .container {
                display: flex;
                flex-direction: column-reverse;

                .update-card {
                    display: flex;
                    flex-direction: row;
                    gap: 2vw;

                    .date-connector-card {
                        display: flex;
                        flex-direction: column-reverse;
                        position: relative;
                        top: -6.5vw;

                        .date-card {
                            display: flex;
                            flex-direction: row;
                            gap: 0.4vw;

                            .date {
                                width: 5vw;
                                background: linear-gradient(to bottom right, #F06F6F, #933EB1);
                                color: white;
                                padding: 0.2vw;
                                text-align: center;
                                font-size: 1vw;
                                border-radius: 0.6vw;
                            }
                            
                            .bubble {
                                width: 0.8vw;
                                height: 0.8vw;
                                border-radius: 1vw;
                                padding: 0.2vw;
                                margin-top: auto;
                                margin-bottom: auto;
                                background: linear-gradient(to right, #F06F6F, #933EB1);
                            }
                        }

                        .line-connector {
                            width: 0.8vw;
                            height: 14vw;
                            background: linear-gradient(to bottom, #F06F6F, #933EB1);
                            border-radius: 1vw;
                            position: relative;
                            right: -6vw;
                        }
                    }

                    .content {
                        width: 30vw;
                        overflow-y: hidden;
                        display: flex;
                        flex-direction: column;
                        gap: 2vw;
                        padding: 1vw;
                        border-radius: 1vw;
                        background: linear-gradient(to right, #F06F6F, #933EB1);
                        opacity: .9;

                        &.opp {
                            opacity: 0.75;
                        }

                        .title {
                            font-size: 1.5vw;
                            font-family: "Inkut Antiqua";
                            color: white;
                        }

                        .desc {
                            font-size: 1vw;
                            font-family: "Inkut Antiqua";
                            color: white;
                        }
                    }
                }
            }
        }

        .register-container {
            background: white;
            border: 1px solid #EFEFEF;
            width: 30vw;
            height: fit-content;
            border-radius: 2vw;
            padding: 2vw;
            display: flex;
            flex-direction: column;

            .header {
                color: black;
                text-align: center;
                font-size: 2vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .sub-header {
                text-align: center;
                font-size: 1vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 1vw;
                margin-bottom: 1vw;
                width: 23vw;
                margin-right: auto;
                margin-left: auto;
            }

            input {
                margin-bottom: 2vw;
                margin-top: 2vw;
                padding: 1vw;
                border: 1px solid white;
                border-radius: 2vw;
                outline: 1px solid white;
            }

            .submit {
                background: linear-gradient(to right, #F06F6F, #933EB1);
                border-radius: 2vw;
                padding: 1vw;
                display: flex;
                flex-direction: row;
                column-gap: 0.5vw;
                width: fit-content;
                margin-right: auto;
                margin-lefT: auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: white;
            }

            .login-text {
                font-size: 1vw;
                margin-top: 1vw;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
