.SettingsPage {
    .contents {
        width: 75vw;
        margin-right: auto;
        margin-left: auto;

        .banner {
            width: 75vw;
            height: 15vw;
            object-fit: cover;
            border-radius: 1vw;
            display: flex;
        }

        .header {
            width: 70vw;
            margin-right: auto;
            margin-left: auto;
            font-size: 4vw;
            font-family: "Expletus Sans";
            margin-top: 1vw;
            margin-bottom: 1vw;
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }

        .form-control {
            width: 70vw;
            margin-right: auto;
            margin-left: auto;
            margin-top: 1vw;
            border-radius: 1vw;
            padding: 0.5vw;
            font-family: "Expletus Sans";

            .link {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .label {
                    input {
                        width: 30vw;
                    }
                }
            }

            .label {
                display: flex;
                flex-direction: column;
                color: black;
                font-size: 2vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 1vw;
                margin-top: 1vw;

                input {
                    border: none;
                    border-radius: 2vw;
                    padding: 1vw;
                    outline: none;
                    color: black;
                    font-family: "Expletus Sans";
                    width: 68vw;
                    margin-top: 1vw;
                }
            }
        }
    }

    .teams {

        .addTeamMember {
            width: 20vw;
            min-height: 25vw;
            border-radius: 2vw;
            position: relative;
            top: 5vw;
            left: 43vw;
            background: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1vw;
            padding-top: 4vw;
            gap: 2vw;

            .title {
                font-size: 2vw;
                background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
            }

            input {
                width: 80%;
                height: 2vw;
                border-radius: 2vw;
                padding: 0.5vw;
                text-align: center;
                outline: none;
                border: none;
            }

            .button {
                background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                color: white;
                width: 10vw;
                font-size: 2vw;
                text-align: center;
                padding: 0.4vw;
                border-radius: 1vw;
                margin-right: auto;
                margin-left: auto;
                cursor: pointer;

                &:hover {
                    background-image: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                }
            }
        }
        
        .header-group {
            display: flex;
            flex-direction: row;

            .header {
                width: 75vw;
            }

            .button {
                height: fit-content;
                width: fit-content;
                display: flex;
                flex-direction: row;
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;

                .icon {
                    height: 1vw;
                    width: 1vw;
                    padding: 0.5vw;
                    border-radius: 1vw;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    font-size: 2vw;
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    font-weight: bold;
                    font-family: 'Arial Rounded MT Bold', 'Poppins', 'Roboto', sans-serif;
                    
                    .plus {
                        width: 20vw;
                        height: 10vw;
                        fill: white;
                    }
                }

                .text {
                    width: 6vw;
                    display: flex;
                    font-size: 1vw;
                    justify-content: center;
                    align-items: center;
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            gap: 2vw;
            align-items: center;
            
            .save, .reset, .delete {
                background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                color: white;
                border-radius: 1vw;
                padding: 0.5vw;
                width: 8vw;
                height: fit-content;
                text-align: center;
                font-size: 1.5vw;
                cursor: pointer;

                &:hover {
                    background: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                }
            }

            .reset {
                width: 10vw;
            }
        }

        .team-table {
            width: 75vw;
            border-radius: 0.5vw;
            padding: 1vw;
            margin-right: auto;
            margin-left: auto;

            .table-headers, .team-data {
                display: grid;
                grid-template-columns: 25vw 25vw 15vw 10vw;
            }

            .team-data {
                .th {
                    display: flex;
                    flex-direction: row;
                    gap: 1vw;
                    margin-top: auto;
                    margin-bottom: auto;

                    .profile {
                        width: 2vw;
                        height: 2vw;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                    }

                    .name {
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .trash, .resend {
                        background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                        width: fit-content;
                        height: fit-content;
                        border-radius: 0.5vw;
                        padding: 0.2vw;
                        cursor: pointer;
                        margin-top: auto;
                        margin-bottom: auto;
                        display: none;
                    }

                    .icon {
                        width: 1vw;
                        height: 1vw;
                        cursor: pointer;
                        fill: white;
                        margin-right: 1vw;
                        display: flex;
                        margin-right: auto;
                        margin-left: auto;
                    }

                    .arrowLeft {
                        fill: black;
                        margin-left: -0.5vw;
                        transform: rotate(-90deg);
                        width: 0.8vw;
                        height: 0.8vw;
                        display: flex;
                        margin-top: auto;
                        margin-bottom: auto;
                        cursor: pointer;

                        &.active {
                            z-index: 2;
                            transform: rotate(90deg);
                        }
                    }

                    .accountType.active {
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .options-container {

                        .background {
                            position: fixed;
                            width: 100vw;
                            height: 100vh;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            background: black;
                        }

                        .options {
                            position: relative;
                            top: 4.5vw;
                            left: -10vw;
                            padding: 1vw;
                            border-radius: 1vw;
                            width: 8vw;
                            display: flex;
                            flex-direction: column;
                            gap: 1vw;
                            z-index: 2;

                            .opt {
                                cursor: pointer;

                                &:hover {
                                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                                    color: white;
                                    border-radius: 0.5vw;
                                    padding: 0.5vw;
                                }
                            }
                        }
                    }

                    &:hover {
                        .trash, .resend {
                            display: flex;
                        }
                    }
                }
            }

            .table-content {
                display: flex;
                flex-direction: column;
                gap: 2vw;
                margin-top: 2vw;
            }
        }
    }

    .org {

        .banner {
            cursor: pointer;
        }

        div.banner {
            .upload-banner {
                position: fixed;
                padding: 1vw;
                border-radius: 2vw;
                font-size: 2vw;
                color: white;
                background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                top: 5vw;
                left: 50vw;
                display: none;
            }   

            &:hover {
                .upload-banner {
                    display: block;
                }
            }
        }

        .organizer-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1vw;

            .pic-container {
                display: flex;
                flex-direction: row;
                height: fit-content;
                gap: 0.5vw;

                .profile-cont {
                    cursor: pointer;

                    .profile {
                        width: 5vw;
                        height: 5vw;
                        border-radius: 5vw;
                        object-fit: cover;
                    }

                    .icon {
                        fill: white;
                        width: 4vw;
                        height: 4vw;
                        position: fixed;
                        top: 16.8vw;
                        left: 20.5vw;
                        display: none;
                    }

                    &:hover {
                        .icon {
                            display: block;
                        }
                    }
                }

                .username {
                    margin-top: auto;
                    margin-bottom: auto;
                    font-size: 2vw;
                    font-family: "Expletus Sans";
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 2vw;
                align-items: center;
                
                .delete, .save, .reset {
                    background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    color: white;
                    border-radius: 1vw;
                    padding: 0.5vw;
                    width: 8vw;
                    height: fit-content;
                    text-align: center;
                    font-size: 1.5vw;
                    cursor: pointer;
    
                    &:hover {
                        background: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                    }
                }

                .reset {
                    width: 10vw;
                }
            }
        }
    }
    

    .profile {

        .profile-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 2vw;

            .pic-container {
                display: flex;
                flex-direction: row;
                height: fit-content;
                gap: 0.5vw;

                .profile-cont {
                    cursor: pointer;

                    .profile {
                        width: 5vw;
                        height: 5vw;
                        border-radius: 5vw;
                        object-fit: cover;
                    }

                    .icon {
                        fill: white;
                        width: 4vw;
                        height: 4vw;
                        position: fixed;
                        top: 17.8vw;
                        left: 20.5vw;
                        display: none;
                    }

                    &:hover {
                        .icon {
                            display: block;
                        }
                    }
                }

                .username {
                    margin-top: auto;
                    margin-bottom: auto;
                    font-size: 2vw; 
                    font-family: "Expletus Sans";
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 2vw;
                align-items: center;
                
                .save, .reset {
                    background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    color: white;
                    border-radius: 1vw;
                    padding: 0.5vw;
                    width: 8vw;
                    height: fit-content;
                    text-align: center;
                    font-size: 1.5vw;
                    cursor: pointer;
    
                    &:hover {
                        background: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                    }
                }

                .reset {
                    width: 10vw;
                }
            }
        }

        .collapsible-account-deletion {
            width: 71vw;
            margin-right: auto;
            margin-left: auto;
            margin-top: 2vw;

            .collapsible {
                width: 100%;
            }

            .button {
                display: flex;
                flex-direction: row;
                align-items: center;
                background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                color: white;
                margin-top: 2vw;
                margin-bottom: 1vw;
                border-radius: 0.5vw;
                padding: 0.8vw;
                width: 12vw;
                height: fit-content;
                text-align: center;
                justify-content: center;
                font-size: 1.5vw;
                cursor: pointer;
            
                &:hover {
                    background: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                }
            }

            .confirm-popup {
                width: 35vw;
                border-radius: 2vw;
                position: relative;
                top: 15vw;
                left: 34.5vw;
                background: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1vw;
                padding-top: 4vw;

                p {
                    text-align: center;
                }

                input {
                    width: 80%;
                    height: 2vw;
                    border-radius: 2vw;
                    padding: 0.5vw;
                    text-align: center;
                    outline: none;
                    border: none;
                }

                .error {
                    color: red;
                }
            }
        }
    }

    .dropDownClassTeamData {
        margin-left: 1.5vw;
        margin-top: auto;
        margin-bottom: auto;
        width: fit-content;

        .dropDownClassTeamDataOptions {
            background: white;
            width: 5vw;
            height: 1vw;
            border-radius: 0.5vw;
            padding: 0.5vw;
            padding-left: 1vw;
            padding-right: 1vw;
            margin-top: 0.2vw;
            border: 1px solid gainsboro;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;
            position: relative;
            right: 2.7vw;
            
            

            &:hover {
                background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                border: none;
                color: white;
            }
        }
    
        .selector {
            width: 5vw;
            height: 1vw;
            border-radius: 0.5vw;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            cursor: pointer;
            padding: 0.5vw;
            padding-left: 1vw;
            padding-right: 1vw;
            flex-wrap: wrap;
            align-content: center;
            position: relative;
            right: 2.7vw;

            .placeholder {
                margin-right: auto;
                margin-left: auto;
                font-size: 1vw;
                color: rgb(139, 139, 139);
            }
    
            .arrow {
                width: .5vw;
                height: .5vw;
                transform: rotate(-90deg);
    
                &.active {
                    transform: rotate(90deg);
                }
            }
        }
    }

}
