.EventDetailsPage {
    .content {
        display: flex;
        flex-direction: column;
        padding-left: 4vw;

        .header {
            display: flex;
            flex-direction: row;
            justify-self: space-between;
            gap: 44vw;
            margin-top: 1vw;

            .title {
                font-size: 3vw;
                color: black;
                font-family: "Inkut Antiqua";
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 3vw;

                .button {
                    background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    width: 6vw;
                    border-radius: 1vw;
                    padding: 0.7vw;
                    font-size: 1vw;
                    margin-top: auto;
                    margin-bottom: auto;
                    color: white;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                    }
                }
            }
        }
    }
}
