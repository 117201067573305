.event-details-content {
    display: grid;
    grid-template-columns: 50vw 25vw;
    gap: 2vw;

    .form {
        margin-top: 2vw;
        display: flex;
        flex-direction: column;
        gap: 2vw;
        
        .label-container {
            display: flex;
            flex-direction: column;
            gap: 0.5vw;
            background: white;
            border-radius: 1vw;
            padding: 0.5vw;

            .label {
                font-size: 1vw;
                color: rgb(78, 78, 78);
                font-style: bold;
            }

            input, .textarea {
                height: 2vw;
                background: white;
                color: black;
                outline: none;
                border: none;
            }

            .textarea {
                height: 17vw;
                font-family: "Expletus Sans" !important;
                overflow-y: auto;
            }

            &.form-2 {    
                width: 23.5vw;
            }

            &.sales {
                margin-top: -5vw;

                .content {
                    display: flex;
                    flex-direction: row;
                    gap: 1vw;
                    margin-bottom: 0.5vw;
    
                    svg {
                        width: 1.5vw;
                        height: 1.5vw;
                        fill: white;
                        stroke: white;
                        background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                        padding: 0.5vw;
                        border-radius: 1.5vw;
                        margin-left: -4vw;
                    }
    
                    .text {
                        margin-top: auto;
                        margin-bottom: auto;
                        font-size: 2vw;
                    }
                }
            }

            &.icon-dropdown {
                .dropdown-container {
                    .selector, .options-container {
                        width: 22vw;
                        box-shadow: none;

                        .options {
                            width: 21vw;
                            height: fit-content;
                            box-shadow: none;
                            margin-top: 1vw;
                            margin-bottom: 1vw;
                            border: none;

                            &:hover {
                                .text { 
                                    color: white;
                                }
                            }
                        }
                    }

                    .icon-opts {
                        display: flex;
                        flex-direction: row;
                        gap: 1vw;
    
                        .icon {
                            width: 2vw;
                            height: 2vw;
                            fill: white;
                            stroke: white;
                            background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                            padding: 0.2vw;
                            border-radius: 1.5vw;
                            margin-left: -11.5vw;

                            &.lock {
                                fill: transparent;
                            }
                        }
    
                        .text {
                            margin-top: auto;
                            margin-bottom: auto;
                            font-size: 2vw;
                            color: black;
                        }
                    }
                }
            }
        }

        .date-selectors {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &.time {
                margin-bottom: 2vw;
            }
        }

        .image-uploader {
            .banner {
                width: 24.5vw;
                height: 12vw;
                border-radius: 1vw;
                cursor: pointer;
                object-fit: cover;
            }

            .upload-banner {
                position: relative;
                padding: 1vw;
                border-radius: 2vw;
                font-size: 2vw;
                color: white;
                background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                width: 18vw;
                text-align: center;
                top: -9vw;
                left: 2.8vw;
                visibility: hidden;
                display: block;
                cursor: pointer;
            }   

            &:hover {
                .upload-banner {
                    visibility: visible;
                }
            }
        }

        .qr-code-container {
            border-radius: 1.5vw;
            width: 24.5vw;

            canvas {
                width: 22vw;
                border-radius: 1.5vw;
                height: 14vw;
                margin-right: auto;
                margin-left: auto;
                display: flex;
            }

            .download-icon {
                width: 2vw;
                height: 2vw;
                fill: transparent;
                stroke: white;
                background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                padding: 0.5vw;
                border-radius: 1.5vw;
                position: fixed;
                top: 35vw;
                left: 92vw;
                visibility: hidden;
                cursor: pointer;
            }

            &:hover {
                .download-icon {
                    visibility: visible;
                }
            }
        }
    }
}
