
.date-selector-container {
    align-items: center;
    justify-content: space-between;
    padding-top: 0.2vw;
    padding-left: 0.5vw;
    border-radius: 2vw;
    background-color: white;
    width: 20vw;
    height: 6vw;
    font-family: 'Expletus Sans', sans-serif;
    color: black;

    .dropdowns {
        display: flex;
        gap: 2vw;
        width: 3vw;

        .date-selector {
            width: 3vw;
            text-align: center;
        }

        .date-options-container {
            max-height: 10vw;
            width: 6vw;
            overflow-y: auto;

            .date-options {
                width: 3vw;
                display: flex;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
            }
        }

        .options {
            width: 3vw;
        }
    }
}

