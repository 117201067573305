.settings {
    width: 15vw;

    .logo {
        text-align: center;
        font-size: 3.5vw;
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        gap: 1vw;

        .items-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2vw;

            .title {
                color: rgb( 43, 43, 43 );
                font-size: 3vw;
                margin-left: 1vw;
            }

            .menu-item {
                display: flex;
                flex-direction: row;
                margin-right: auto;
                margin-left: auto;
                width: 12vw;
                padding: 0.6vw;
                border-radius: 1vw;
                
                cursor: pointer;

                &:hover {
                    background: white;
                    box-shadow: 2px 12px 20px rgba( 0, 0, 0, 0.25 );
                }

                &.active {
                    background: white;

                    .icon-wrapper {
                        background: linear-gradient(to bottom right, #F34A4A, #AA49CC);

                        svg {
                            stroke: white;
                        }

                        .gallery {
                            stroke: white;
                        }

                        .sales {
                            fill: white !important;
                        }

                        .analysis {
                            stroke: none;
                            fill: white;

                            #gradient1 {
                                .gradient-start{
                                    stop-color: white !important;
                                }

                                .gradient-end {
                                    stop-color: white !important;
                                }
                            }
                        }
                    }
                }

                .icon {
                    border-radius: 0.7vw;
                    padding: 0.2vw;
                    width: 2.5vw;
                    height: 2vw;
                    object-fit: cover;
                }

                .icon-wrapper {
                    padding: 0.2vw;
                    border-radius: 0.7vw;
                    background: white;

                    svg {
                        width: 2vw;
                        height: 1.5vw;
                        fill: rgb( 46, 46, 46 );
                        stroke: rgb( 46, 46, 46 );

                        &.back {
                            fill: black;
                        }
                    }

                    .gallery {
                        fill: transparent !important;
                    }

                    .sales {
                        fill: black !important;
                    }
                }

                .menu-title {
                    font-size: 1vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 0.5vw;
                }
            }

            .banner-org {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                img {
                    width: 12vw;
                    height: 5vw;
                    border-radius: 1.5vw;
                    padding: 0.5vw;
                    margin-right: auto;
                    margin-left: auto;
                }

                .orgName {
                    width: 12vw;
                    margin-right: auto;
                    margin-left: auto;
                    margin-top: 1vw;
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }

            .eventTitle {
                font-size: 1vw;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                
                margin-top: 0.85vw;
               
            }
                .eventName {
                font-size: 1vw;
                display: flex;
                justify-content: center;
                align-items: center;
                
                }

            

        }
    }
}
