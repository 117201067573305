.EventsPage {

    gap: 5vw;

    .content {
        margin-right: auto;
        margin-left: auto;

        .hover-fixed-content {
            position: sticky;
            top: 0;
            left: 10.9vw;
            width: fit-content;
            background: white;
        }

        .header {
            width: 70vw;
            border-radius: 2vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-right: auto;
            margin-left: auto;
            margin-top: 2vw;
            margin-bottom: 1vw;
            padding: 0.8vw;

            .title {
                font-size: 1.5vw;
                font-family: "Expletus Sans";
                margin-top: auto;
                margin-bottom: auto;
            }

            .button {
                display: flex;
                flex-direction: row;
                gap: 1vw;
                cursor: pointer;

                &:hover {
                        
                    svg {
                        background: linear-gradient(to bottom left, #F34A4A, #AA49CC);
                    }
                }

                svg {
                    width: 1vw;
                    height: 1vw;
                    background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    border-radius: 2vw;
                    padding: 1vw;
                }
            }
        }

        .loadmore {
            width: 5vw;
            margin-right: auto;
            margin-left: auto;
            margin-top: 2vw;
            border: none;
            background: linear-gradient(to right, #FF1083, #933EB1);
            color: white;
            padding: 0.8vw 2vw;
            border-radius: 0.6vw;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: linear-gradient(to bottom left, #FF1083, #933EB1);
            }
        }

        .table {
            width: 70vw;
            border-collapse: collapse;
            margin-right: auto;
            margin-left: auto;
            padding: 0.8vw;
            display: flex;
            flex-direction: column;

            .eventCard {
                cursor: pointer;
            }

            .row {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 1vw;
                margin-top: 0.5vw;

                .col {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .name {
                        width: 25vw;
                        display: flex;
                        flex-direction: row;
                        margin-top: auto;
                        margin-bottom: auto;
                        gap: 0.5vw;

                        img {
                            width: 2vw;
                            height: 2vw;
                            border-radius: 1vw;
                            object-fit: cover;
                        }

                        .event-name {
                            font-size: 1.5vw;
                            display: flex;
                            margin-top: auto;
                            margin-bottom: auto;
                            color: black;
                        }
                    }

                    .visibility {
                        width: 8vw;
                        display: flex;
                        flex-direction: row;
                        gap: 1vw;
                        margin-top: auto;
                        margin-bottom: auto;

                        .icon {
                            width: 1.5vw;
                            height: 1.5vw;
                            padding: 0.5vw;
                            border-radius: 1vw;
                            display: flex;
                            justify-content: center;
                            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                            
                            svg {
                                width: 1.5vw;
                                height: 1.5vw;
                                fill: white;

                                &.lock {
                                    fill: transparent;
                                    stroke: white;
                                }
                            }
                        }

                        .text {
                            font-size: 1.5vw;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }

                    .view {
                        width: 4vw;
                        text-align: center;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .date-created {
                        width: 5vw;
                        text-align: center;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .event-date {
                        width: 8vw;
                        text-align: center;
                        margin-top: auto;
                        margin-bottom: auto;
                    }


                }

                .divider {
                    height: 0.01vw;
                    width: 70vw;
                    border-radius: 2vw;
                    padding: 0.1vw;
                    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                }
            }
        }
    }

    .addEvent {
        width: 25vw;
        min-height: 25vw;
        border-radius: 2vw;
        position: relative;
        top: 8vw;
        left: 4.5vw;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1vw;
        padding-top: 4vw;
        gap: 2vw;

        &.withError {
            top: 6vw;
        }

        .error {
            color: red;
        }

        .title {
            font-size: 2vw;
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }

        input {
            width: 80%;
            height: 2vw;
            border-radius: 2vw;
            padding: 0.5vw;
            text-align: center;
            outline: none;
            border: none;

            &.desc {
                height: 9vw;
            }
        }

        .button {
            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
            color: white;
            width: 15vw;
            font-size: 2.5vw;
            text-align: center;
            padding: 0.5vw;
            border-radius: 1vw;
            margin-right: auto;
            margin-left: auto;
            cursor: pointer;

            &:hover {
                background-image: linear-gradient(to bottom left, #F34A4A, #AA49CC);
            }
        }
    }
}
