.custom-alert {
    position: fixed;
    top: 48%;
    left: 54%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    min-width: 300px;
    max-width: 600px;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    animation: fadeIn 0.5s ease-in-out;

    &.success {
        background-color: #d4edda;
        color: #155724;
        border: 1px solid #c3e6cb;
    }

    &.error {
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
    }

    &.info {
        background-color: white;
        color: black;
    }

    &.warning {
        background-color: #fff3cd;
        color: #856404;
        border: 1px solid #ffeeba;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
