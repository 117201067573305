.event-gallery-content {
    .header {
        .button {
            display: flex;
            flex-direction: row;
            gap: 2vw;
            position: relative;
            left: 61vw;
            cursor: pointer;
            margin-bottom: 2vw;
            margin-top: 2vw;

            .plus {
                width: 2vw;
                height: 2vw;
                fill: white;
                background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                padding: 0.5vw;
                border-radius: 1vw;
            }

            .text {
                margin-top: auto;
                margin-bottom: auto;
                font-size: 2vw;
            }
        }
    }

    .gallery-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
        grid-gap: 6vw;

        .loadmore {
            width: 5vw;
            margin-right: auto;
            margin-left: auto;
            background: linear-gradient(to right, #FF1083, #933EB1);
            color: white;
            padding: 0.8vw 2vw;
            border-radius: 0.6vw;
            text-align: center;
            cursor: pointer;
            position: relative;
            left: 26vw;

            &:hover {
                background: linear-gradient(to bottom left, #FF1083, #933EB1);
            }
        }
    }
}
